import clsx from "clsx";
import React from "react";
import LoaderSpinnerIcon from "../../icons/LoaderSpinnerIcon";

const Skeleton = ({ className, isSpinner }) => {
  return (
    <div className={clsx("skeleton", className)}>
      {isSpinner && <LoaderSpinnerIcon />}
    </div>
  );
};

export default Skeleton;
