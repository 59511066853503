import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { StyledTextField } from "../../../components/Inputs/Input";
import styles from "./TopUpModal.module.scss";
import CloseIcon from "../../../icons/CloseIcon";

export const AccountForm = ({
  accounts,
  setAccountsArray,
  index,
  removeAccount,
  setFormCount,
}) => {
  const [accountIban, setAccountIban] = useState("");
  const [accountValue, setAccountValue] = useState();

  const handleAccountChange = (event) => {
    setAccountIban(event.target.value);
  };

  useEffect(() => {
    if (accountIban && accountValue) {
      setAccountsArray((prevArray) => {
        const updatedArray = [...prevArray];
        updatedArray[index] = { id: accountIban, amount: accountValue };
        return updatedArray;
      });
    }
  }, [accountIban, accountValue, index, setAccountsArray]);

  return (
    <>
      <div className={styles.accountFormContainer}>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{
            maxHeight: "40px",
            width: "100%",
            "& .MuiInputBase-root": {
              height: "40px",
            },
          }}
        >
          <InputLabel
            id="network-select-label"
            shrink
            sx={{
              backgroundColor: "white",
              padding: "0px",
            }}
          >
            Account #{index + 1}
          </InputLabel>
          <Select
            labelId="network-select-label"
            id="network-select"
            value={accountIban}
            label="Select the account"
            onChange={handleAccountChange}
          >
            {accounts.map((account, idx) => (
              <MenuItem key={idx} value={account.iban}>
                {account.iban} {` `} ({account.accountName})
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <StyledTextField
          id={"amounts"}
          label="Top up amount"
          variant="outlined"
          placeholder="Enter an amount"
          InputLabelProps={{
            shrink: true,
          }}
          value={accountValue}
          onChange={(e) => setAccountValue(e.target.value)}
          style={{ maxHeight: "40px" }}
        />
        {index === 0 ? (
          ""
        ) : (
          <Button
            onClick={() => removeAccount(index)}
            className={styles.deleteButton}
          >
            <CloseIcon />
          </Button>
        )}
      </div>
    </>
  );
};
