import { axiosAuth } from "./axios";
import { API_PATHS } from "./constants";

export const getAllNews = async () => {
  const news = await axiosAuth.get(API_PATHS.ALL_NEWS, {});
  return news.data;
};

// для квери танстак
export const getAllNew = async ({ queryKey }) => {
  const [, params] = queryKey;
  const response = await axiosAuth.get(API_PATHS.ALL_NEWS, { params });

  return response;
};

export const getNewsArticle = async (id) => {
  const news = await axiosAuth.get(`${API_PATHS.ALL_NEWS}/${id}`);
  return news;
};
