const TelegramIcon = ({
  style = {},
  viewBox = "0 0 24 24",
  size = "24",
  className = "",
}) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox={viewBox}
    style={style}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.3744 11.3895C7.74118 9.05132 11.3199 7.50983 13.1105 6.76505C18.223 4.63856 19.2854 4.26917 19.9778 4.25697C20.1301 4.25429 20.4706 4.29203 20.6912 4.47101C21.0214 4.73894 21.0204 5.31167 20.984 5.69429C20.7069 8.60529 19.5081 15.6695 18.8982 18.9299C18.6402 20.3094 18.132 20.772 17.6401 20.8173C16.571 20.9157 15.7592 20.1108 14.7238 19.432C13.1035 18.3699 12.1881 17.7087 10.6153 16.6723C8.79774 15.4745 9.97602 14.8162 11.0119 13.7403C11.283 13.4587 15.9934 9.17429 16.0845 8.78562C16.0959 8.73702 16.1065 8.55582 15.9989 8.46015C15.8912 8.36447 15.7323 8.39719 15.6177 8.42321C15.4552 8.46009 12.8668 10.1709 7.85241 13.5558C7.1177 14.0603 6.45221 14.3061 5.85596 14.2932C5.19864 14.279 3.93423 13.9215 2.99426 13.616C1.84136 13.2412 0.925057 13.0431 1.00484 12.4066C1.0464 12.0751 1.50292 11.7361 2.3744 11.3895Z"
      fill="white"
    />
  </svg>
);

export default TelegramIcon;
