import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { PAGE_ROUTES } from "../../constants";
import { changeUserPassword } from "../../../api/authApi";
import { resendNew2faCode } from "../../../api/twoFactorAuthentication";
import styles from "../change/styles.module.scss";
import useTimeOutLine from "../../../hooks/useTimeOutLine";
import { getUserData } from "../../../api/userApi";
import { showToast } from "../../../modules/Notification/ActionNotification";
import { NOTIFICATION_INFO } from "../../../constants/constants";

const ChangeProfilePassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [profileData, setProfileData] = useState();
  const { timeLeft, isCodeTimeout, startTimer } = useTimeOutLine();

  const {
    handleSubmit,
    register,
    setError,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
      codeTwoFa: "",
    },
  });
  const { password, confirmPassword, codeTwoFa } = watch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getUserData();
        setProfileData(result.data);
      } catch (error) {
        console.error("Error in receiving profile data.", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (errors.serverError) {
      clearErrors("serverError");
    }
  }, [password, confirmPassword, codeTwoFa, errors.serverError]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const body = {
        password: data.password,
        password_confirmation: data.confirmPassword,
        code: data.codeTwoFa,
      };

      await changeUserPassword(body);
      showToast("changePassword", NOTIFICATION_INFO.PASSOWRD_CHANGED);

      setIsLoading(false);
      navigate(PAGE_ROUTES.PROFILE);
    } catch (error) {
      if (error.response.data) {
        setError("serverError", { message: error.response.data.data.message });
      }
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleSendTwoFaCode = async () => {
    try {
      if (profileData) {
        const email = profileData.email;
        startTimer();

        await resendNew2faCode({ email });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleBackToProfile = () => {
    return navigate(PAGE_ROUTES.PROFILE);
  };

  console.log("isCodeTimeout", isCodeTimeout);
  return (
    <div className={styles.profileChangePas}>
      <h1>Change password</h1>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.profileChangeForm}
      >
        <div className={styles.profileChangeContainer}>
          <div className={styles.profileChangeInputs}>
            {errors.serverError && <p>{errors.serverError.message}</p>}
            <p className="semiHedline">New password</p>
            <div className={styles.containerInput}>
              <input
                placeholder="Type here a new password"
                {...register("password", {
                  required: "Name is required",
                  minLength: {
                    value: 8,
                    message:
                      "The password field must contain at least 8 characters",
                  },
                })}
              />
              {errors.password && <p>{errors.password.message}</p>}
            </div>
          </div>
          <div className={styles.profileChangeInputs}>
            <p className="semiHedline">Confirm password</p>
            <div className={styles.containerInput}>
              <input
                placeholder="Type it again"
                {...register("confirmPassword", {
                  required: "Name is required",
                  minLength: {
                    value: 8,
                    message:
                      "The confirm password field must contain at least 8 characters",
                  },
                  validate: (value) => {
                    return value === password || "Passwords do not match";
                  },
                })}
              />
              {errors.confirmPassword && (
                <p>{errors.confirmPassword.message}</p>
              )}
            </div>
          </div>
          <div className={styles.profileChangeInputs}>
            <p className="semiHedline">E-mail 2FA</p>
            <div className={styles.profileChangeInputFA}>
              <div className={styles.containerInput}>
                <div className={styles.containerWith2Fa}>
                  <input
                    style={{ minWidth: "330px", maxWidth: "330px" }}
                    placeholder="Enter the 2FA code sent to your email"
                    {...register("codeTwoFa", {
                      required: "E-mail 2FA is required",
                    })}
                  />
                  {isCodeTimeout ? (
                    <Button
                      onClick={handleSendTwoFaCode}
                      className={styles.resendCode}
                    >
                      Resend code
                    </Button>
                  ) : (
                    <div className={styles.twaPageTimer}>{timeLeft} sec</div>
                  )}
                </div>
                {errors.codeTwoFa && <p>{errors.codeTwoFa.message}</p>}
              </div>
            </div>
          </div>
          <div className={styles.changeProfileButtons}>
            <Button type="submit" isLoading={isLoading}>
              Change
            </Button>
            <Button
              type="button"
              className={styles.cancelBtn}
              onClick={handleBackToProfile}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChangeProfilePassword;
