import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../components/Button/Button.jsx";
import ArrowLeft from "../../icons/ArrowLeft.jsx";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { getNewsArticle } from "../../api/newsApi.js";
import InnerHTML from "../../components/innerHTML/InnerHTML.jsx";
import { DateFormatter } from "../../components/DateFormatter/DateFormatter.jsx";
import LoaderSpinnerIcon from "../../icons/LoaderSpinnerIcon.jsx";

const NewsArticle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [newsData, setNewsData] = useState(null);

  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        const response = await getNewsArticle(id);
        setNewsData(response.data.data);
      } catch (error) {
        console.error("Error fetching news data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNewsData();
  }, [id]);

  return (
    <div className={styles.newsArticle}>
      <div className={styles.articleTitle}>
        <h1>News</h1>
        <Button
          onClick={() => navigate("/news")}
          className={styles.articleTitleButton}
        >
          <ArrowLeft />
          Go Back
        </Button>
      </div>
      {!loading && newsData ? (
        <div className={styles.articleItem}>
          <h1>
            {newsData.title}{" "}
            <span className={styles.articleTitleId}>{newsData.id}</span>
          </h1>
          <InnerHTML className={styles.articleItemText}>
            {newsData.content}
          </InnerHTML>
          <p className={`${styles.articleItemDate} secondaryText`}>
            Published at :
            <DateFormatter
              withTime={false}
              date={
                newsData.created_at || newsData.published_at || newsData.updated
              }
            />
          </p>
        </div>
      ) : (
        <div className={styles.newsArticleSceleton}>
          <LoaderSpinnerIcon />
        </div>
      )}
    </div>
  );
};

export default NewsArticle;
