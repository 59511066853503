import { axiosPublic } from "./axios";
import { API_PATHS } from "./constants";

export const requestVerificationCode = async (data) => {
  const result = await axiosPublic.post(API_PATHS.SEND_RECOVER_CODE, data);
  return result.data;
};

export const recoverPasswordCode = async (data) => {
  const result = await axiosPublic.post(API_PATHS.CHANGE_PASSWORD, data);
  return result.data;
};
