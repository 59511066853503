const EyeOpened = ({
  style = {},
  viewBox = "0 0 24 24",
  size = "24",
  className = "",
  onClick,
  isClosed = { isClosed },
}) =>
  isClosed ? (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51472 14.4853 7.5 12 7.5ZM9 12C9 10.3431 10.3431 9 12 9C13.6568 9 15 10.3431 15 12C15 13.6569 13.6568 15 12 15C10.3431 15 9 13.6569 9 12Z"
        fill="#AAAAAA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9996 3.75C7.0014 3.75 2.69703 6.71633 0.738701 10.9835C0.442569 11.6288 0.442569 12.3712 0.738701 13.0165C2.69703 17.2837 7.0014 20.25 11.9996 20.25C16.9978 20.25 21.3022 17.2837 23.2605 13.0165C23.5567 12.3712 23.5567 11.6288 23.2605 10.9835C21.3022 6.71633 16.9978 3.75 11.9996 3.75ZM2.10199 11.6091C3.82551 7.85362 7.61059 5.25 11.9996 5.25C16.3886 5.25 20.1737 7.85362 21.8972 11.6091C22.0111 11.8572 22.0111 12.1428 21.8972 12.3909C20.1737 16.1464 16.3886 18.75 11.9996 18.75C7.61059 18.75 3.82551 16.1464 2.10199 12.3909C1.98814 12.1428 1.98813 11.8572 2.10199 11.6091Z"
        fill="#AAAAAA"
      />
    </svg>
  ) : (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.03032 1.96967C2.73743 1.67678 2.26256 1.67678 1.96966 1.96967C1.67677 2.26256 1.67677 2.73744 1.96966 3.03033L4.91734 5.97801C3.11632 7.23804 1.66529 8.96527 0.739067 10.9835C0.442935 11.6288 0.442935 12.3712 0.739067 13.0165C2.6974 17.2837 7.00177 20.25 12 20.25C14.0821 20.25 16.0437 19.7353 17.7656 18.8262L20.9697 22.0303C21.2626 22.3232 21.7374 22.3232 22.0303 22.0303C22.3232 21.7374 22.3232 21.2626 22.0303 20.9697L3.03032 1.96967ZM16.6478 17.7085L14.6074 15.6681C13.8718 16.1919 12.9719 16.5 12 16.5C9.51471 16.5 7.49999 14.4853 7.49999 12C7.49999 11.0281 7.80809 10.1282 8.33193 9.3926L5.99675 7.05742C4.31182 8.17569 2.95489 9.7515 2.10236 11.6091C1.9885 11.8572 1.98851 12.1428 2.10236 12.3909C3.82588 16.1464 7.61096 18.75 12 18.75C13.6623 18.75 15.238 18.3765 16.6478 17.7085ZM9.41536 10.476C9.15144 10.9227 8.99999 11.4436 8.99999 12C8.99999 13.6569 10.3431 15 12 15C12.5564 15 13.0773 14.8486 13.524 14.5846L9.41536 10.476Z"
        fill="#AAAAAA"
      />
      <path
        d="M11.021 7.60679L12.4473 9.03313C13.7461 9.2273 14.7727 10.2539 14.9669 11.5527L16.3932 12.979C16.4631 12.6638 16.5 12.3362 16.5 12C16.5 9.51472 14.4853 7.5 12 7.5C11.6638 7.5 11.3362 7.53687 11.021 7.60679Z"
        fill="#AAAAAA"
      />
      <path
        d="M21.8976 12.3909C21.2814 13.7336 20.4017 14.929 19.3251 15.9109L20.3866 16.9724C21.5881 15.8642 22.5704 14.5212 23.2609 13.0165C23.5571 12.3712 23.5571 11.6288 23.2609 10.9835C21.3026 6.71633 16.9982 3.75 12 3.75C10.5519 3.75 9.16209 3.99898 7.87067 4.45645L9.06529 5.65107C9.99905 5.3897 10.9833 5.25 12 5.25C16.389 5.25 20.1741 7.85362 21.8976 11.6091C22.0115 11.8572 22.0115 12.1428 21.8976 12.3909Z"
        fill="#AAAAAA"
      />
    </svg>
  );
export default EyeOpened;
