export const PAGE_ROUTES = {
  LOGIN: "/login",
  TWO_FA_AFTER_LOGIN: "/2fa",
  RECOVER_PASSWORD: "/recover-password",
  RESET_PASSWORD: "/reset-password/:token",
  NOT_FOUND: "/not-found",
  NEWS: "/news",
  NEWS_ARTICLE: "/news-article/:id",

  //footer
  YOUR_MANAGER: "https://t.me/fbm_evan",
  TG_CHANNEL: "https://t.me/fbm_pay",

  //menu
  DASHBOARD: "/dashboard",

  ACCOUNTS: "/accounts",
  TEAM_MANAGEMENT: "/team-management",
  CARDS: "/cards",
  PAYMENTS: "/payments",
  METAPAY_AND_3DS: "/metapay-and-3ds",
  FBM_SERVICES: "/fbm-services",

  //profile
  PROFILE: "/profile",
  PROFILE_CHANGE: "/profile/change",
  PROFILE_PASSWORD_CHANGE: "/profile/change-password",
};
