import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { PAGE_ROUTES } from "../pages/constants";
import { axiosAuth } from "../api/axios";
import { showToast } from "../modules/Notification/ActionNotification";
import { NOTIFICATION_INFO } from "../constants/constants";
import { useQueryClient } from "@tanstack/react-query";

const useLogoutUser = ({ setToken, token }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const logout = useCallback(() => {
    localStorage.removeItem("token");
    setToken("");
    queryClient.clear();
    navigate(PAGE_ROUTES.LOGIN);
    showToast("blocked", NOTIFICATION_INFO.ACCOUNT_BLOCKED_INFORM);
  }, []);

  useEffect(() => {
    const requestInterceptor = axiosAuth.interceptors.request.use(
      (config) => {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseInterceptor = axiosAuth.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          logout();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosAuth.interceptors.request.eject(requestInterceptor);
      axiosAuth.interceptors.response.eject(responseInterceptor);
    };
  }, [token, logout]);
};

export default useLogoutUser;
