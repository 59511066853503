import React from "react";
import { getAllNew } from "../../../api/newsApi";
import { Link } from "react-router-dom";
import { DateFormatter } from "../../DateFormatter/DateFormatter";

import ArrowTop from "../../../icons/ArrowTop";
import { truncateTitle } from "../../../utils/truncateTitle";
import { useQuery } from "@tanstack/react-query";
import Skeleton from "../../Skeleton/Skeleton";

import styles from "../styles.module.scss";
import clsx from "clsx";
import { useAuthQuery } from "../../../hooks/useAuthQuery";

const ContainerNews = () => {
  const { data, isLoading, isFetching } = useAuthQuery({
    queryKey: ["news", { perPage: 5 }],
    queryFn: getAllNew,
    staleTime: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    refetchInterval: 10 * 60 * 1000,
  });

  const news = data?.data?.data || [];

  return (
    <div className={styles.containerNewsTitle}>
      {isFetching || isLoading ? (
        <Skeleton className={styles.newsSkeleton} />
      ) : (
        <>
          {news.length > 0 ? (
            news.map((el) => {
              return (
                <Link
                  key={el.id}
                  to={`/news-article/${el.id}`}
                  className={styles.containerNewOne}
                >
                  <div>
                    <p
                      className={clsx(
                        "body1",
                        styles.titleNewsColor,
                        styles.truncate
                      )}
                    >
                      {truncateTitle(el.title, 20)}
                    </p>
                    <DateFormatter
                      date={el.created_at}
                      isLoading={isLoading}
                      className={clsx("body2", styles.dateColor)}
                      withTime={false}
                    />
                  </div>

                  <div className={styles.linkCircle}>
                    <ArrowTop />
                  </div>
                </Link>
              );
            })
          ) : (
            <p className={styles.noDataAvailableTitle}>
              NO DATA AVAILABLE FOR DISPLAY
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(ContainerNews);
