const TeamManagmentIcon = ({ fill = "#232323" }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3C9.51472 3 7.5 5.01472 7.5 7.5C7.5 9.98527 9.51472 12 12 12C14.4853 12 16.5 9.98527 16.5 7.5C16.5 5.01472 14.4853 3 12 3ZM9 7.5C9 5.84314 10.3432 4.5 12 4.5C13.6568 4.5 15 5.84314 15 7.5C15 9.15683 13.6568 10.5 12 10.5C10.3432 10.5 9 9.15683 9 7.5Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.75 10.3125C0.75 8.5521 2.17709 7.125 3.9375 7.125C5.69791 7.125 7.125 8.5521 7.125 10.3125C7.125 12.0729 5.69791 13.5 3.9375 13.5C2.17709 13.5 0.75 12.0729 0.75 10.3125ZM3.9375 8.625C3.00552 8.625 2.25 9.38055 2.25 10.3125C2.25 11.2445 3.00552 12 3.9375 12C4.86948 12 5.625 11.2445 5.625 10.3125C5.625 9.38055 4.86948 8.625 3.9375 8.625Z"
          fill={fill}
        />
        <path
          d="M20.3718 15.8668C20.6867 15.9211 20.9967 15.9995 21.2809 16.1123C21.6683 16.2661 21.9665 16.4684 22.167 16.7226C22.3592 16.9664 22.4999 17.3051 22.4999 17.8124V18.7499C22.4999 19.1641 22.8356 19.4999 23.2499 19.4999C23.6641 19.4999 23.9999 19.1641 23.9999 18.7499V17.8124C23.9999 16.9985 23.7639 16.325 23.3447 15.7937C22.934 15.2729 22.386 14.9372 21.8343 14.7182C21.0794 14.4185 20.2425 14.309 19.5508 14.2703C19.9137 14.7445 20.1902 15.2785 20.3718 15.8668Z"
          fill={fill}
        />
        <path
          d="M4.44906 14.2703C3.75733 14.309 2.92049 14.4185 2.16553 14.7182C1.61387 14.9372 1.06591 15.2729 0.655114 15.7937C0.235973 16.325 0 16.9985 0 17.8124V18.7499C0 19.1641 0.335786 19.4999 0.75 19.4999C1.16421 19.4999 1.5 19.1641 1.5 18.7499V17.8124C1.5 17.3051 1.64062 16.9664 1.83283 16.7226C2.03341 16.4684 2.33158 16.2661 2.71901 16.1123C3.00313 15.9995 3.31322 15.921 3.62808 15.8668C3.80961 15.2785 4.08614 14.7445 4.44906 14.2703Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.0625 7.125C18.3021 7.125 16.875 8.5521 16.875 10.3125C16.875 12.0729 18.3021 13.5 20.0625 13.5C21.8229 13.5 23.25 12.0729 23.25 10.3125C23.25 8.5521 21.8229 7.125 20.0625 7.125ZM18.375 10.3125C18.375 9.38055 19.1305 8.625 20.0625 8.625C20.9945 8.625 21.75 9.38055 21.75 10.3125C21.75 11.2445 20.9945 12 20.0625 12C19.1305 12 18.375 11.2445 18.375 10.3125Z"
          fill={fill}
        />
        <path
          d="M6.59089 15.9345C6.24008 16.4021 6 17.038 6 17.9356V18.75C6 19.1642 5.66421 19.5 5.25 19.5C4.83579 19.5 4.5 19.1642 4.5 18.75V17.9356C4.5 16.7495 4.8248 15.7891 5.39103 15.0343C5.95102 14.2879 6.70911 13.7928 7.49951 13.4629C9.05753 12.8126 10.8774 12.75 12 12.75C13.1226 12.75 14.9425 12.8126 16.5005 13.4629C17.2909 13.7928 18.049 14.2879 18.609 15.0343C19.1752 15.7891 19.5 16.7495 19.5 17.9356V18.75C19.5 19.1642 19.1642 19.5 18.75 19.5C18.3358 19.5 18 19.1642 18 18.75V17.9356C18 17.038 17.7599 16.4021 17.4091 15.9345C17.0521 15.4586 16.541 15.1052 15.9227 14.8471C14.6635 14.3215 13.1083 14.25 12 14.25C10.8917 14.25 9.33652 14.3215 8.07728 14.8471C7.45906 15.1052 6.94795 15.4586 6.59089 15.9345Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default TeamManagmentIcon;
