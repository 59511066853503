import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./styles.module.scss";

export const ActionNotification = ({
  position = "top-right",
  autoClose = 1500,
}) => {
  return (
    <ToastContainer
      position={position}
      autoClose={autoClose}
      toastClassName={styles.customToast}
      className={styles.customContainer}
    />
  );
};

export const showToast = (type, message, options = {}) => {
  const defaultOptions = {
    position: options.position || "top-right",
    autoClose: options.autoClose || 1500,
    hideProgressBar: options.hideProgressBar || false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...options,
  };
  const toastTypes = {
    success: [
      "login",
      "changePassword",
      "success",
      "removeAccount",
      "unblocked",
      "accGranted",
      "accUpdate",
      "accCreate",
    ],
    error: ["error", "blocked", "unauthorized"],
  };

  const toastFunctions = {
    success: toast.success,
    error: toast.error,
    info: toast.info,
    warning: toast.warn,
    default: toast,
  };

  const toastFunction =
    Object.keys(toastTypes).find((key) => toastTypes[key].includes(type)) ||
    "default";

  toastFunctions[toastFunction](message, defaultOptions);
};
