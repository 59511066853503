const CardsIcon = ({ fill = "#232323" }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.62477 16.5C5.21055 16.5 4.87487 16.1642 4.875 15.75C4.87513 15.3358 5.21102 15 5.62523 15H7.12523C7.53945 15 7.87515 15.3358 7.875 15.75C7.87485 16.1642 7.539 16.5 7.12477 16.5H5.62477Z"
          fill={fill}
        />
        <path
          d="M8.625 15.75C8.62485 16.1642 8.96055 16.5 9.37477 16.5H10.8748C11.289 16.5 11.6249 16.1642 11.625 15.75C11.6251 15.3358 11.2895 15 10.8752 15H9.37523C8.961 15 8.62515 15.3358 8.625 15.75Z"
          fill={fill}
        />
        <path
          d="M13.1248 16.5C12.7106 16.5 12.3749 16.1642 12.375 15.75C12.3752 15.3358 12.711 15 13.1252 15H14.6252C15.0394 15 15.3751 15.3358 15.375 15.75C15.3749 16.1642 15.039 16.5 14.6248 16.5H13.1248Z"
          fill={fill}
        />
        <path
          d="M16.125 15.75C16.1249 16.1642 16.4606 16.5 16.8748 16.5H18.3748C18.789 16.5 19.1249 16.1642 19.125 15.75C19.1251 15.3358 18.7894 15 18.3752 15H16.8752C16.461 15 16.1252 15.3358 16.125 15.75Z"
          fill={fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.375 6.75C14.3395 6.75 13.5 7.58948 13.5 8.625V9.375C13.5 10.4105 14.3395 11.25 15.375 11.25H17.625C18.6605 11.25 19.5 10.4105 19.5 9.375V8.625C19.5 7.58948 18.6605 6.75 17.625 6.75H15.375ZM15 8.625C15 8.41793 15.1679 8.25 15.375 8.25H17.625C17.8321 8.25 18 8.41793 18 8.625V9.375C18 9.58208 17.8321 9.75 17.625 9.75H15.375C15.1679 9.75 15 9.58208 15 9.375V8.625Z"
          fill={fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.625 3.75C3.34682 3.75 1.5 5.59682 1.5 7.875V16.125C1.5 18.4032 3.34682 20.25 5.625 20.25H18.375C20.6532 20.25 22.5 18.4032 22.5 16.125V7.875C22.5 5.59682 20.6532 3.75 18.375 3.75H5.625ZM3 7.875C3 6.42525 4.17525 5.25 5.625 5.25H18.375C19.8248 5.25 21 6.42525 21 7.875V16.125C21 17.5747 19.8248 18.75 18.375 18.75H5.625C4.17525 18.75 3 17.5747 3 16.125V7.875Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default CardsIcon;
