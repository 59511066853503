const InnerHTML = ({ children, className }) => {
  const isHTML = (text) => /<\/?[a-z][\s\S]*>/i.test(text);

  const removeFigcaption = (htmlString) => {
    return htmlString.replace(/<figcaption[^>]*>.*?<\/figcaption>/gi, "");
  };

  return (
    <>
      {isHTML(children) ? (
        <div
          className={`${className} primaryText`}
          dangerouslySetInnerHTML={{
            __html: removeFigcaption(children),
          }}
        />
      ) : (
        <p className={`${className} primaryText`}>{children}</p>
      )}
    </>
  );
};

export default InnerHTML;
