import { Navigate } from "react-router-dom";
import { PAGE_ROUTES } from "./constants";
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";

export const PrivateRoute = ({ children, allowedRoles }) => {
  const { token, userData, userDataIsLoading } = useContext(AuthContext);

  if (!token) {
    return <Navigate to={PAGE_ROUTES.LOGIN} replace />;
  }

  if (userDataIsLoading) {
    return null;
  }

  if (allowedRoles && !allowedRoles.includes(userData.role && userData.role)) {
    return <Navigate to={PAGE_ROUTES.DASHBOARD} replace />;
  }

  return children;
};
