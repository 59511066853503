import styles from "./styles.module.scss";
import { getAllNews } from "../../api/newsApi";
import NewsItem from "../../components/newsItem/NewsItem";
import LoaderSpinnerIcon from "../../icons/LoaderSpinnerIcon";
import { useAuthQuery } from "../../hooks/useAuthQuery";

const News = () => {
  // const [newsData, setNewsData] = useState(null);

  const { data, isLoading, isFetching } = useAuthQuery({
    queryKey: ["allNews"],
    queryFn: getAllNews,
    staleTime: 3 * 60 * 1000,
    cacheTime: 3 * 60 * 1000,
    refetchInterval: 5 * 60 * 1000,
  });

  const news = data?.data || [];

  return (
    <div className={styles.newsPage}>
      <h1>News</h1>
      {isLoading || isFetching ? (
        <div className={styles.newsPageSceleton}>
          <LoaderSpinnerIcon />
        </div>
      ) : (
        <div className={styles.newsPageContainer}>
          {news.length ? <NewsItem data={news} /> : <p>No Data</p>}
        </div>
      )}
    </div>
  );
};

export default News;
