import React, { useEffect, useState } from "react";
import styles from "./TopUpModal.module.scss";
import { StyledTextField } from "../../../components/Inputs/Input";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { fundAccount, getAccounts } from "../../../api/getAccounts";
import SuccessIcon from "../../../icons/SuccessIcon";
import { AccountForm } from "./AccountsForm";
import tether from "../../../images/tether.png";
import ethereum from "../../../images/Ethereum.png";
import ArrowRight from "../../../icons/ArrowRight";
import QestionIcon from "../../../icons/QestionIcon";
import ShowMoreIcon from "../../../icons/ShowMoreIcon";

const WalletTopUpModal = ({ setTopUpModal, onClose, tableData }) => {
  const [accounts] = useState(tableData);
  const [step, setStep] = useState(1);
  const [formCount, setFormCount] = useState([1]);
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [accountsArray, setAccountsArray] = useState([]);
  const [summValue, setSummValue] = useState();
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore((prev) => !prev);
  };

  const addAccountForm = () => {
    setFormCount((prevCount) => [...prevCount, prevCount.length + 1]);
  };
  console.log(selectedNetwork);
  const sumValues = (accounts) => {
    const total = accounts.reduce((total, account) => {
      return total + Number(account.amount);
    }, 0);
    setSummValue(total);
  };

  const handleNetworkChange = (event) => {
    setSelectedNetwork(event.target.value);
  };

  const removeAccount = (index) => {
    setAccountsArray((prevArray) =>
      prevArray.filter((_, idx) => idx !== index)
    );
    setFormCount((prevCount) => prevCount.filter((_, idx) => idx !== index));
  };

  const handleNextStep = () => {
    if (step === 1 && accountsArray.length > 0) {
      sumValues(accountsArray);
      setStep(2);
    } else if (step === 2 && selectedNetwork !== "") {
      setStep(3);
      // const body = {
      //   accounts: accountsArray,
      //   cryptoCurrency: selectedNetwork,
      // };
      // console.log("body request", body);
      // const fetchFundDetails = async (body) => {
      //   const result = await fundAccount(body);

      //   console.log(result);
      // };
      // fetchFundDetails();
    }
  };

  const handlePreviousStep = () => {
    if (step === 2) {
      sumValues(accountsArray);
      setStep(1);
    } else if (step === 3) {
      setStep(2);
    }
  };

  console.log(summValue);
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <h1>
          Top up balance
          {summValue ? ` for $ ${summValue.toFixed(2)}` : ""}
        </h1>
        <div className={styles.modalItemsGap}>
          <div className={styles.modalContainer}>
            <div className={styles.sideSteps}>
              <div className={styles.stepRound}>
                {step === 1 ? "1" : <SuccessIcon />}
              </div>
              <div className={styles.stepLine}></div>
            </div>
            <div className={styles.singleStep}>
              <div className={styles.stepTitle}>
                <h2>Step 1</h2>
                <p className="secondaryText" style={{ color: "#AAAAAA" }}>
                  Accounts and amount in $
                </p>
              </div>
              <div
                className={`${styles.step} ${
                  step === 1 ? styles.activeStep : ""
                }`}
              >
                <p className="primaryText" style={{ paddingTop: "8px" }}>
                  Select the accounts and the amount you want to top them up
                  with.
                </p>
                {formCount.map((_, idx) => (
                  <AccountForm
                    key={idx}
                    accounts={accounts}
                    setAccountsArray={setAccountsArray}
                    index={idx}
                    removeAccount={removeAccount}
                  />
                ))}

                <div className={styles.buttonsContainer}>
                  <button
                    onClick={handleNextStep}
                    className={styles.continueBtn}
                  >
                    Continue
                  </button>
                  <button onClick={addAccountForm}>Add account</button>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.modalContainer}>
            <div className={styles.sideSteps}>
              <div
                className={`${styles.stepRound} ${
                  step < 2 ? styles.stepInactive : ""
                }`}
              >
                {step < 2 ? "2" : step > 2 ? <SuccessIcon /> : "2"}
              </div>
              <div className={styles.stepLine}></div>
            </div>
            <div className={styles.singleStep}>
              <div
                className={`${styles.stepTitle} ${
                  step < 2 ? styles.textInactive : ""
                }`}
              >
                <h2>Step 2</h2>
                <p className="secondaryText" style={{ color: "#AAAAAA" }}>
                  Wallet network
                </p>
              </div>
              <div
                className={`${styles.step} ${
                  step === 2 ? styles.activeStep : ""
                }`}
              >
                <FormControl
                  fullWidth
                  sx={{
                    maxHeight: "40px",
                    width: "100%",
                    "& .MuiInputBase-root": {
                      height: "40px",
                    },
                  }}
                >
                  <InputLabel
                    id="network-select-label"
                    shrink
                    sx={{
                      backgroundColor: "white",
                      padding: "0px",
                    }}
                  >
                    Wallet Network
                  </InputLabel>
                  <Select
                    labelId="network-select-label"
                    id="network-select"
                    value={selectedNetwork}
                    label="Wallet network"
                    onChange={handleNetworkChange}
                  >
                    <MenuItem value={"USDTTRC20"}>
                      <div className={styles.topUpNetworkList}>
                        <p>USDT TRC20</p>
                        <img src={tether} alt="TRC20" />
                      </div>
                    </MenuItem>
                    <MenuItem value={"USDTERC20"}>
                      <div className={styles.topUpNetworkList}>
                        <p>USDT ERC20</p>
                        <img src={ethereum} alt="ERC20" />
                      </div>
                    </MenuItem>
                  </Select>
                </FormControl>
                <div className={styles.buttonsStepsContainer}>
                  <button
                    onClick={handleNextStep}
                    className={styles.continueBtn}
                  >
                    Continue
                  </button>
                  <button onClick={handlePreviousStep}>Back</button>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.modalContainer}>
            <div className={styles.sideSteps}>
              <div
                className={`${styles.stepRound} ${
                  step < 3 ? styles.stepInactive : ""
                }`}
              >
                {step < 3 ? "3" : step > 3 ? <SuccessIcon /> : "3"}
              </div>
            </div>
            <div className={styles.singleStep}>
              <div
                className={`${styles.stepTitle} ${
                  step < 3 ? styles.textInactive : ""
                }`}
              >
                <h2>Step 3</h2>
                <p className="secondaryText" style={{ color: "#AAAAAA" }}>
                  Payment and confirmation
                </p>
              </div>
              <div
                className={`${styles.step} ${
                  step === 3 ? styles.activeStep : ""
                }`}
              >
                <div className={styles.thirdStepContainer}>
                  <p className="primaryText" style={{ color: "#464646" }}>
                    Transfer USDT to the specified wallet.{" "}
                    <span style={{ fontWeight: "700" }}>
                      Wallet network is {selectedNetwork}
                    </span>
                  </p>
                  <div className={styles.thirdStepTransferInformation}>
                    <div className={styles.thirdStepContainerInput}>
                      <h1>$ {summValue ? summValue.toFixed(2) : ""}</h1>{" "}
                      <ArrowRight />
                      <StyledTextField
                        id={"amounts"}
                        label="Wallet address for transfer"
                        variant="outlined"
                        placeholder="Enter an amount"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={"TPaxqbmSTw1QiXDUfwZr4qF8vP1dfqhq6T  "}
                        style={{ maxHeight: "40px", width: "70%" }}
                      />
                    </div>
                    <div className={styles.thirdStepFee}>
                      <span>
                        10:00 <QestionIcon />
                      </span>
                      <div className={styles.thirdStepFeeContainer}>
                        <span>
                          $ {summValue ? summValue.toFixed(2) : ""}
                          <QestionIcon />
                        </span>
                        <span
                          style={{
                            border: "1px solid var(--Primary-Black, #232323)",
                            width: "16px",
                          }}
                        ></span>
                        <div className={styles.thirdStepServiceFee}>
                          <span>
                            $ {summValue ? summValue.toFixed(2) * 0.045 : ""}
                          </span>
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "12px",
                              color: "#AAAAAA",
                              paddingLeft: "4px",
                            }}
                          >
                            Service <br /> fee 4,5%
                          </span>
                        </div>
                        <span
                          style={{
                            borderTop:
                              "2px solid var(--Primary-Black, #232323)",
                            borderBottom:
                              "2px solid var(--Primary-Black, #232323)",
                            width: "16px",
                            height: "8px",
                          }}
                        ></span>
                        <div>
                          <div className={styles.thirdStepServiceFee}>
                            <span>
                              $ {summValue ? summValue.toFixed(2) * 0.955 : ""}
                            </span>
                            <span
                              style={{
                                fontWeight: "500",
                                fontSize: "12px",
                                color: "#AAAAAA",
                                paddingLeft: "4px",
                              }}
                            >
                              Will be credited <br /> to the accounts
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p>
                      After making the transfer, paste the txnHash in the field
                      below.
                    </p>
                    <input type="text" placeholder="Transaction hash txnHash" />
                    <div className={styles.thirdStepAttention}>
                      <div className={styles.thirdStepAttentionTitle}>
                        <h3 style={{ color: "#FF4D4D" }}>Attention</h3>{" "}
                        <button
                          onClick={toggleShowMore}
                          className={
                            showMore ? styles.thirdStepAttentionTitleButton : ""
                          }
                        >
                          <ShowMoreIcon />
                        </button>
                      </div>

                      <p
                        className={`secondaryText ${styles.thirdStepAttentionText}`}
                      >
                        It’s strictly forbidden to top up balance from{" "}
                        <span>Garantex</span> , <span>Hydra</span> and{" "}
                        <span>Tornado Cash</span> crypto wallets.
                      </p>
                      {showMore && (
                        <p
                          className={`secondaryText ${styles.thirdStepAttentionText}`}
                        >
                          Payments received from such wallets will block your
                          account with all funds without the possibility of
                          return! You will lose your money.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={styles.buttonsStepsContainer}>
                    <button
                      onClick={handleNextStep}
                      className={styles.continueBtn}
                    >
                      Continue
                    </button>
                    <button onClick={handlePreviousStep}>Back</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          className={styles.closeBtn}
          onClick={() => setTopUpModal(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default WalletTopUpModal;
