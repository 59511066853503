export const ButtonMode = {
  GREEN_GRADIENT: "green-gradient",
};
export const LOGIN_REG_EXP = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const SECONDS_TO_TESEND_2FA_CODE = 5;

export const HIGHLIGH_POSITION_LAST = "last";

export const NOTIFICATION_INFO = {
  LOGIN: "Welcome to FPM[PAY}",
  PROFILE_DATA_CHANGED: "Profile data changed",
  PASSOWRD_CHANGED: "Password changed",
  ACCOUNT_REMOVED: "Account successfully removed",
  ACCOUNT_BLOCKED_ACTION: "Account successfully blocked",
  ACCOUNT_UNBLOCKED: "Account successfully unblocked",
  ACCOUNT_GRANTED: "Account successfully granted",
  ACCOUNT_CREATED: "Account successfully created",
  ACCOUNT_UPDATED: "Account successfully updated",
  ACCOUNT_BLOCKED_INFORM: "Your account has been banned",
};
