import ItemBlockInfo from "../../components/itemBlockInfo/ItemBlockInfo";
import { PAGE_ROUTES } from "../../pages/constants";
import { HIGHLIGH_POSITION_LAST } from "../../constants/constants";
import styles from "./styles.module.scss";
import React from "react";
import smallCard from "../../images/smallCreditCard.png";
import clsx from "clsx";
import Skeleton from "../../components/Skeleton/Skeleton";
import DashBoardTable from "../Tables/DashBoard/DashBoardTable";

const DashBoardItems = ({ data, isLoading, isFetching }) => {
  const dashboardData = data?.data?.data || {};

  return (
    <>
      <div className={styles.containerDashboard}>
        <div className={styles.containerWidgets}>
          <ItemBlockInfo
            title="TOTAL SPEND FOR"
            titleColored="TODAY"
            additContent={
              isLoading || isFetching ? (
                <Skeleton className={styles.widgetSkeleton} />
              ) : (
                <div className="H1">
                  €{dashboardData.totalSpendToday?.value || 0}
                </div>
              )
            }
            linkTitle="Details"
            href={`${PAGE_ROUTES.PAYMENTS}?spend-for-day=true`}
            highlightPosition={HIGHLIGH_POSITION_LAST}
          />
          <ItemBlockInfo
            title="TOTAL SPEND FOR"
            titleColored="NOVEMBER"
            additContent={
              isLoading || isFetching ? (
                <Skeleton className={styles.widgetSkeleton} />
              ) : (
                <div className="H1">
                  €{dashboardData.totalSpendMonth?.value || 0}
                </div>
              )
            }
            linkTitle="Details"
            href={`${PAGE_ROUTES.PAYMENTS}?spend-for-month=true`}
            highlightPosition={HIGHLIGH_POSITION_LAST}
          />
          <ItemBlockInfo
            title="CARDS ISSUED TODAY"
            titleColored=""
            additContent={
              isLoading || isFetching ? (
                <Skeleton className={styles.widgetSkeletonWithCard} />
              ) : (
                <div className="H1">
                  <div className={styles.containerImgCard}>
                    <p>
                      {dashboardData.cardsIssuedToday?.value || 0}{" "}
                      <span className={clsx("body2", styles.cardText)}>
                        Cards
                      </span>
                    </p>
                    <img src={smallCard} alt="" />
                  </div>
                </div>
              )
            }
            linkTitle="All cards"
            href={`${PAGE_ROUTES.CARDS}?issued-today=true`}
          />
          <ItemBlockInfo
            title="ALL VALID CARDS"
            titleColored=""
            additContent={
              isLoading || isFetching ? (
                <Skeleton className={styles.widgetSkeletonWithCard} />
              ) : (
                <div className="H1">
                  <div className={styles.containerImgCard}>
                    <div className={styles.conainerAllCards}>
                      {dashboardData.allValidCards?.value || 0}
                      <div className={styles.twoRowsText}>
                        <span className={clsx("body2", styles.cardText)}>
                          All valid
                        </span>
                        <span className={clsx("body2", styles.cardText)}>
                          team cards
                        </span>
                      </div>
                    </div>
                    <img src={smallCard} alt="" />
                  </div>
                </div>
              )
            }
            linkTitle="All cards"
            href={PAGE_ROUTES.CARDS}
            highlightPosition={HIGHLIGH_POSITION_LAST}
          />
        </div>
        <div className={styles.containerTableDashboard}>
          <DashBoardTable />
        </div>
      </div>
    </>
  );
};

export default React.memo(DashBoardItems);
