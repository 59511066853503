const LogoIcon = () => (
  <svg
    width="206"
    height="39"
    viewBox="0 0 206 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2821_5888)">
      <rect width="205.372" height="39" rx="8.55451" fill="white" />
      <path
        d="M45.9355 33.5749V7.91137H53.6346V13.0441H51.0683V28.4422H53.6346V33.5749H45.9355ZM56.201 31.0085V10.4777H76.7318V15.6104H61.3337V18.1768H74.1654V23.3095H61.3337V31.0085H56.201ZM94.6962 25.8758V23.3095H84.4308V25.8758H94.6962ZM94.6962 18.1768V15.6104H84.4308V18.1768H94.6962ZM79.2981 31.0085V10.4777H97.2626L99.8289 13.0441V18.1768L97.2626 20.7431L99.8289 23.3095V28.4422L97.2626 31.0085H79.2981ZM104.962 31.0085L102.395 28.4422V10.4777H107.528L112.661 15.6104L117.793 10.4777H122.926V31.0085H117.793V18.1768L112.661 23.3095L107.528 18.1768V31.0085H104.962ZM125.492 33.5749V28.4422H128.059V13.0441H125.492V7.91137H133.192V33.5749H125.492Z"
        fill="#232323"
      />
      <path
        d="M151.156 18.1768V15.6104H140.891V18.1768H151.156ZM135.758 31.0085V10.4777H153.722L156.289 13.0441V20.7431L153.722 23.3095H140.891V31.0085H135.758ZM174.253 20.7431V15.6104H163.988V20.7431H174.253ZM158.855 31.0085V13.0441L161.421 10.4777H176.82L179.386 13.0441V31.0085H174.253V25.8758H163.988V31.0085H158.855ZM192.218 31.0085L189.651 28.4422V22.0263L181.952 14.3273V10.4777H185.802L192.218 16.8936L198.634 10.4777H202.483V14.3273L194.784 22.0263V31.0085H192.218Z"
        fill="url(#paint0_linear_2821_5888)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.77835 27.1382C3.99796 27.359 4.22835 27.5894 4.46956 27.8306L11.1481 34.5091C13.8528 37.2138 15.2052 38.5662 16.894 38.5868C18.5827 38.6075 19.9678 37.2886 22.7378 34.6508L28.9228 28.7611L34.6544 22.738C37.2905 19.9679 38.6085 18.5828 38.5875 16.8945C38.5666 15.2061 37.2147 13.8542 34.5108 11.1503L28.9894 5.62887L28.9899 5.62835L25.6919 2.33036L25.6667 2.35553C24.274 1.07262 23.2737 0.426569 22.1131 0.409055C20.4314 0.383674 19.0461 1.68775 16.2756 4.2959L16.2756 4.2959L10.0881 10.1207L4.2947 16.2758C1.68698 19.0464 0.383119 20.4316 0.408569 22.1133C0.426132 23.2739 1.07224 24.2742 2.35527 25.667L2.33122 25.691L3.77835 27.1382ZM3.77835 27.1382L5.62921 28.989L28.9894 5.62887L27.8308 4.47029C26.9969 3.6364 26.2922 2.93173 25.6667 2.35553L2.35527 25.667C2.76468 26.1114 3.23896 26.5958 3.77835 27.1382Z"
        fill="url(#paint1_linear_2821_5888)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.77835 27.1382C3.99796 27.359 4.22835 27.5894 4.46956 27.8306L11.1481 34.5091C13.8528 37.2138 15.2052 38.5662 16.894 38.5868C18.5827 38.6075 19.9678 37.2886 22.7378 34.6508L28.9228 28.7611L34.6544 22.738C37.2905 19.9679 38.6085 18.5828 38.5875 16.8945C38.5666 15.2061 37.2147 13.8542 34.5108 11.1503L28.9894 5.62887L28.9899 5.62835L25.6919 2.33036L25.6667 2.35553C24.274 1.07262 23.2737 0.426569 22.1131 0.409055C20.4314 0.383674 19.0461 1.68775 16.2756 4.2959L16.2756 4.2959L10.0881 10.1207L4.2947 16.2758C1.68698 19.0464 0.383119 20.4316 0.408569 22.1133C0.426132 23.2739 1.07224 24.2742 2.35527 25.667L2.33122 25.691L3.77835 27.1382ZM3.77835 27.1382L5.62921 28.989L28.9894 5.62887L27.8308 4.47029C26.9969 3.6364 26.2922 2.93173 25.6667 2.35553L2.35527 25.667C2.76468 26.1114 3.23896 26.5958 3.77835 27.1382Z"
        fill="url(#paint2_linear_2821_5888)"
      />
      <g filter="url(#filter0_d_2821_5888)">
        <rect
          x="24.0635"
          y="14.9458"
          width="7.05319"
          height="4.97872"
          rx="1.24468"
          transform="rotate(-45.0102 24.0635 14.9458)"
          fill="white"
        />
      </g>
      <g filter="url(#filter1_i_2821_5888)">
        <rect
          x="2.33301"
          y="25.6909"
          width="33.037"
          height="4.66406"
          transform="rotate(-45 2.33301 25.6909)"
          fill="#005E11"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_2821_5888"
        x="22.9195"
        y="8.81357"
        width="10.7957"
        height="10.7962"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="0.829787" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2821_5888"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2821_5888"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_i_2821_5888"
        x="2.33301"
        y="2.33032"
        width="26.6582"
        height="26.6587"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="0.829787" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2821_5888"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2821_5888"
        x1="137.074"
        y1="9.22462"
        x2="202.432"
        y2="34.643"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#83DA4E" />
        <stop offset="0.465" stopColor="#21A038" />
        <stop offset="0.98" stopColor="#54ACB8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2821_5888"
        x1="20.0782"
        y1="0.426539"
        x2="20.0782"
        y2="39.7295"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#83DA4E" />
        <stop offset="0.5" stopColor="#21A038" />
        <stop offset="1" stopColor="#54ACB8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2821_5888"
        x1="20.0782"
        y1="0.426539"
        x2="20.0782"
        y2="39.7295"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#83DA4E" />
        <stop offset="0.5" stopColor="#21A038" />
        <stop offset="1" stopColor="#54ACB8" />
      </linearGradient>
      <clipPath id="clip0_2821_5888">
        <rect width="205.372" height="39" rx="8.55451" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LogoIcon;
