import { Group, Box, Text } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { getAccounts } from "../../../api/getAccounts";
import styles from "./styles.module.scss";
import Table from "../../../components/Table/Table";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { PAGE_ROUTES } from "../../../pages/constants";
import EditableName from "./EditNamePopup";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import Skeleton from "../../../components/Skeleton/Skeleton";
import { useAuthQuery } from "../../../hooks/useAuthQuery";

const DashBoardTable = () => {
  const {
    data: accountsData,
    isLoading,
    isFetching,
    error: accountsError,
  } = useAuthQuery({
    queryKey: ["dataDashBoardTable"],
    queryFn: getAccounts,
    staleTime: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    refetchInterval: 10 * 60 * 1000,
  });

  const [tableData, setTableData] = useState([]);
  const { userData, userDataIsLoading, errorUserData } =
    useContext(AuthContext);

  useEffect(() => {
    if (accountsData) {
      setTableData(accountsData.data);
    }
  }, [accountsData]);

  if (isLoading || userDataIsLoading || isFetching) {
    return <Skeleton className={styles.dashboardTableSkeleton} />;
  }

  if (accountsError || errorUserData) {
    return (
      <div>
        There was an error: {accountsError?.message || errorUserData?.message}
      </div>
    );
  }

  const columns = [
    {
      accessorKey: "iban",
      header: "IBAN",
      size: 350,
      enableSorting: false,
      Cell: ({ row }) => (
        <Box className={styles.dashboardItemLine}>
          <Text size="sm" color="#AAAAAA" className="secondaryText">
            #{row.original.iban}
          </Text>
          <Group spacing="xs" mt={4}>
            <Text weight="bold" className="semiHedline" color="#232323">
              {row.original.name ? row.original.name : "Unnamed"}
            </Text>
            {userData.role !== "Member" && (
              <EditableName
                account={row.original}
                setTableData={setTableData}
              />
            )}
          </Group>
        </Box>
      ),
    },
    {
      accessorKey: "notification",
      header: "Notification",
      size: 450,
      enableSorting: false,
      Cell: ({ row }) => {
        const balance = row.original.balance;
        const notificationText =
          balance < 1000
            ? "Please top-up your balance"
            : "There is no issue with your account";
        return (
          <Box>
            <Text size="sm" color="dimmed">
              Notification
            </Text>
            <Text size="sm" mt={4}>
              {notificationText}
            </Text>
          </Box>
        );
      },
    },
    {
      accessorKey: "balance",
      header: "Available Balance",
      size: 10,
      enableSorting: false,
      Cell: ({ row }) => (
        <Box>
          <Text size="sm" color="dimmed">
            Available Balance
          </Text>
          <Text size="sm" weight="bold" mt={4}>
            €{row.original.balance ? row.original.balance.toFixed(2) : 0}
          </Text>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <Group position="apart" p="md" style={{ paddingBottom: "26px" }}>
        <Text color="#232323" weight="bold" size="lg" className="H3">
          ACCOUNT'S BALANCES
        </Text>
        <Link
          className={clsx("buttons", styles.showMoreBtn)}
          to={PAGE_ROUTES.ACCOUNTS}
        >
          Show more
        </Link>
      </Group>

      <Table
        columns={columns}
        data={tableData}
        withBorder={false}
        enableTopToolbar={false}
        enablePagination={false}
        enableBottomToolbar={false}
        initialState={{ pageSize: 5 }}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableGlobalFilter={false}
        enableColumnHeaders={false}
        enableFullScreenToggle={false}
        enableHiding={false}
        enableDensityToggle={false}
        mantinePaperProps={{
          shadow: "none",
        }}
        sx={{
          "& th, & td": {
            display: "none",
          },
          "& tr": {
            display: "none",
          },
          "& .mantine-Table-root": {
            border: "none",
          },
        }}
        mantineTableBodyCellProps={{
          sx: {
            border: "none",
          },
        }}
        classNames={{
          root: styles.tableRoot,
        }}
      />
    </Box>
  );
};

export default DashBoardTable;
