import styles from "./styles.module.scss";

const Pagination = ({ tabs, activeTab, setActiveTab }) => {
  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className={styles.tabs}>
      {tabs.map((tab, index) => (
        <button
          key={index}
          className={`${styles.tab} ${
            activeTab === index ? styles.active : ""
          }`}
          onClick={() => handleTabClick(index)}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

export default Pagination;
