import { Navigate, Route, Routes } from "react-router-dom";
import { authRoutes, publicRoutes } from "./pages/routes";
import { PrivateRoute } from "./pages/PrivateRoutes";
import { PAGE_ROUTES } from "./pages/constants";
import MainLayout from "./modules/MainLayout/MainLayout";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";

function App() {
  const { token } = useContext(AuthContext);

  return (
    <Routes>
      <Route
        path="/"
        element={
          token ? (
            <Navigate to={PAGE_ROUTES.DASHBOARD} replace />
          ) : (
            <Navigate to={PAGE_ROUTES.LOGIN} replace />
          )
        }
      />

      <Route element={<MainLayout />}>
        {authRoutes.map(({ path, Element, allowedRoles }) => {
          return (
            <Route
              key={path}
              path={path}
              element={
                <PrivateRoute allowedRoles={allowedRoles}>
                  {<Element />}
                </PrivateRoute>
              }
            />
          );
        })}
      </Route>

      {publicRoutes.map(({ path, Element }) => {
        return <Route key={path} path={path} element={<Element />} />;
      })}

      <Route
        path="*"
        element={<Navigate to={PAGE_ROUTES.NOT_FOUND} replace />}
      />
    </Routes>
  );
}

export default App;
