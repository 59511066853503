import React from "react";

const CloseIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3033_17457)">
        <path
          d="M12.5962 3.40381C12.7914 3.59907 12.7915 3.91566 12.5962 4.11092L8.70711 8.00001L12.5962 11.8891C12.7914 12.0843 12.7914 12.4009 12.5962 12.5962C12.4009 12.7915 12.0843 12.7915 11.8891 12.5962L8 8.70711L4.11091 12.5962C3.91566 12.7915 3.59906 12.7915 3.40381 12.5962C3.20855 12.4009 3.20855 12.0843 3.40381 11.8891L7.29289 8.00001L3.40381 4.11092C3.20855 3.91566 3.20855 3.59907 3.40381 3.40381C3.59906 3.20856 3.91565 3.20855 4.11091 3.40381L8 7.2929L11.8891 3.40381C12.0843 3.20855 12.4009 3.20856 12.5962 3.40381Z"
          fill="#AAAAAA"
        />
      </g>
      <defs>
        <clipPath id="clip0_3033_17457">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseIcon;
