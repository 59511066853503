import { Popover, TextInput } from "@mantine/core";
import { Button } from "../../../components/Button/Button";
import { IconPencil } from "@tabler/icons-react";
import { changeAccount } from "../../../api/getAccounts";
import { useState } from "react";
import styles from "./styles.module.scss";
import Rename from "../../../icons/Rename";

const EditableName = ({ account, setTableData }) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [newName, setNewName] = useState(account.name);

  const handleEditClick = () => setPopoverOpen(true);
  const handleChangeName = async (accountID) => {
    const data = {
      name: newName,
    };
    try {
      await changeAccount(accountID, data);
      setTableData((prevName) => {
        return prevName.map((account) =>
          account.ID === accountID ? { ...account, name: newName } : account
        );
      });
      setPopoverOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Popover
      opened={isPopoverOpen}
      onClose={() => setPopoverOpen(false)}
      position="bottom-start"
      withArrow
    >
      <Popover.Target>
        <div
          onClick={handleEditClick}
          style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        >
          <Rename />
        </div>
      </Popover.Target>
      <Popover.Dropdown className={styles.popOver}>
        <TextInput
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          placeholder="Enter new name"
          mb="sm"
        />
        <Button
          className={styles.saveButton}
          onClick={() => handleChangeName(account.ID)}
        >
          Save
        </Button>
      </Popover.Dropdown>
    </Popover>
  );
};

export default EditableName;
