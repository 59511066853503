import styles from "./styles.module.scss";
import notFoundImg from "../../images/404.svg";
import clsx from "clsx";
import { Button } from "../../components/Button/Button";

const NotFounded = () => {
  return (
    <div className={styles.notFoundContainer}>
      <div className={styles.loginContainer}>
        <img src={notFoundImg} />

        <div className={styles.containerText}>
          <p className="H1">Page not found</p>
          <span className={clsx("body1", styles.subtitle)}>
            The page you requested could not be found
          </span>
        </div>
        <Button className={clsx("buttons", styles.button)}>
          Back To Login
        </Button>
      </div>
    </div>
  );
};

export default NotFounded;
