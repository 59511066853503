import React, { useState } from "react";
import styles from "./styles.module.scss";
import { issueAccounts } from "../../../api/IssueAccount";

const ModalIssueAccount = ({ setIssueModal }) => {
  const [accountName, setAccountName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleCloseModal = () => {
    setIssueModal(false);
  };

  const handleAccountNameChange = (e) => {
    setAccountName(e.target.value);
  };

  const handleCreate = async () => {
    if (!accountName) {
      alert("Please enter an account name");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await issueAccounts(accountName);

      if (response?.status === 200) {
        alert("Account successfully issued!");
        setIssueModal(false);
        console.log(response);
      } else {
        throw new Error("Failed to issue account");
      }
    } catch (err) {
      setError(err.message);
      console.error("Error issuing account:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.modalIssue}>
      <div className={styles.modalIssueContainer}>
        <h1>Issue an account</h1>
        <p className="primaryText">
          To release an account, you need to come up with a name for it.
        </p>
        <input
          type="text"
          placeholder="Enter a name"
          value={accountName}
          onChange={handleAccountNameChange}
        />
        {error && <p className={styles.errorText}>{error}</p>}

        <div className={styles.modalIssueContainerButton}>
          <button
            className={styles.modalIssueContainerIssueButton}
            onClick={handleCreate}
            disabled={loading || !accountName}
          >
            {loading ? "Issuing..." : "Issue"}
          </button>
          <span>€0, It’s free</span>
        </div>
        <button
          className={styles.modalIssueContainerExitButton}
          onClick={handleCloseModal}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ModalIssueAccount;
