import { styled, TextField } from "@mui/material";

export const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    height: "40px",
    padding: "0px",

    "& fieldset": {
      borderColor: "#AAAAAA",
    },
    "&:hover fieldset": {
      borderColor: "#AAAAAA",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#AAAAAA",
    },
    "& .MuiInputBase-input": {
      fontSize: "16px",

      "&::placeholder": {
        fontSize: "14px",
        color: "#AAAAAA",
      },
    },
  },
});
