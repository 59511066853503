import tg from "./images/TG.svg";
import agencyAccountsIcon from "./images/agencyAccounts.png";
import landingsIcon from "./images/landings.png";
import videoCreativesIcon from "./images/videoCreatives.png";
import ASOIcon from "./images/ASO.png";
import boostSocialMediaIcon from "./images/boostSocialMedia.png";
import shopSellingIcon from "./images/shop.png";
import videoReviewsIcon from "./images/videoReviews.png";
import redMediaIcon from "./images/redMedia.png";
import ecosystemTgIcon from "./images/ecosystemTg.png";
import tgChatIcon from "./images/tgChat.png";

import styles from "./styles.module.scss";

export const serviceData = [
  {
    imgSrc: agencyAccountsIcon,
    alt: "Agency accounts",
    title: "Agency accounts",
    text: (
      <>
        Official Facebook, Google, Tik Tok, Taboola agency accounts with credit
        line.{" "}
        <span className={styles.boldText}>
          We allow to put traffic on WH / GH / BH.
        </span>{" "}
        Fast issuing, lowest commission.
      </>
    ),
    span: "@fbm.agency",
    icon: tg,
    buttonText: "Start Now",
    aLink: "https://t.me/fbm_agency",
    buttonLink: "/accounts/all_accounts",
  },
  {
    imgSrc: landingsIcon,
    alt: "Landings and pre-landings",
    title: "Landings and pre-landings",
    text: (
      <>
        Landing pages of any complexity for the desired offer and native selling
        pre-landings with stories on ‘em.
        <span className={styles.boldText}>
          In our portfolio more than 300 sites done.
        </span>
      </>
    ),
    span: "@fbm.landings",
    icon: tg,
    buttonText: "Order Now",
    aLink: "https://t.me/fbm_landings",
    buttonLink: "/prelendi-dlya-arbitrazha-trafika/",
  },
  {
    imgSrc: videoCreativesIcon,
    alt: "Static and video creatives",
    title: "Static and video creatives",
    text: (
      <>
        The best service for creating static banners and video ads on the
        market.{" "}
        <span className={styles.boldText}>
          We made over 3000 creatives so you won’t have any troubles with
          moderation.
        </span>
      </>
    ),
    span: "@fbm.creo",
    icon: tg,
    buttonText: "Order Now",
    aLink: "https://t.me/fbm_creo",
    buttonLink: "/kreativi-dlya-reklami/",
  },
  {
    imgSrc: ASOIcon,
    alt: "[FBM] ASO",
    title: "[FBM] ASO",
    text: (
      <>
        Any volumes of downloads in Google Play and App Store.{" "}
        <span className={styles.boldText}>Price starts from 0.12$.</span>
      </>
    ),
    span: "@fbm.aso",
    icon: tg,
    buttonText: "Start Now",
    aLink: "https://t.me/fbm_apps",
    buttonLink: "https://t.me/fbm_apps",
  },
  {
    imgSrc: boostSocialMediaIcon,
    alt: "Boost for social media",
    title: "Boost for social media",
    text: (
      <>
        We are working with such social media platforms as Facebook, Instagram,
        Tik Tok, and more.{" "}
        <span className={styles.boldText}>Become more popular with us.</span>
      </>
    ),
    span: "@fbm.boost",
    icon: tg,
    buttonText: "Start Now",
    aLink: "https://fbm-boost.com",
    buttonLink: "https://fbm-boost.com",
  },
  {
    imgSrc: shopSellingIcon,
    alt: "Shop selling consumables",
    title: "Shop selling consumables",
    text: (
      <>
        Trusted & farmed FB accounts, proxies, and much more to buy traffic in
        FB easily.{" "}
        <span className={styles.boldText}>
          Video reviews with our actors for any project and niche.
        </span>
      </>
    ),
    span: "@fbm_ibot",
    icon: tg,
    buttonText: "Buy Now",
    aLink: "https://t.me/fbm_ibot",
    buttonLink: "https://t.me/fbm_ibot",
  },
  {
    imgSrc: videoReviewsIcon,
    alt: "Video reviews for your needs",
    title: "Video reviews for your needs",
    text: (
      <>
        With this service, you can order video reviews with our actors for any
        project and niche.{" "}
        <span className={styles.boldText}>
          Who`s looking for work in traffic arbitrage and for companies looking
          for employees.
        </span>
      </>
    ),
    span: "@fbm_reviews",
    icon: tg,
    buttonText: "Order Now",
    aLink: "https://t.me/fbm_reviews",
    buttonLink: "https://t.me/fbm_reviews",
  },
  {
    imgSrc: redMediaIcon,
    alt: "[FBM] RED MEDIA",
    title: "[FBM] RED MEDIA",
    text: (
      <>
        Official media from [FBM] Ecosystem.{" "}
        <span className={styles.boldText}>
          There we publish articles on a variety of topics: bug fixes, life
          hacks, reviews of new products, and much more.
        </span>
      </>
    ),
    span: "@fbm.red",
    icon: tg,
    buttonText: "Go to website",
    aLink: "https://t.me/fbm_red",
    buttonLink: "https://t.me/fbm_red",
  },
  {
    imgSrc: ecosystemTgIcon,
    alt: "[FBM] Ecosystem TG",
    title: "[FBM] Ecosystem TG",
    text: (
      <>
        Our telegram channel where we are posting{" "}
        <span className={styles.boldText}>
          ONLY useful information: ways to escape bugs, make your ads more
          profitable, and so on.
        </span>
      </>
    ),
    span: "@fbm_red",
    icon: tg,
    buttonText: "Subscribe",
    aLink: "https://t.me/fbm_red",
    buttonLink: "https://t.me/fbm_red",
  },
  {
    imgSrc: tgChatIcon,
    alt: "[FBM] Telegram chat",
    title: "[FBM] Telegram chat",
    text: (
      <>
        In our free private chat{" "}
        <span className={styles.boldText}>
          anyone can receive an answer to their question about traffic,
          Facebook, and all this stuff.
        </span>
      </>
    ),
    span: "@fbm_ichat",
    icon: tg,
    buttonText: "Join",
    aLink: "https://t.me/fbm_ichat",
    buttonLink: "https://t.me/fbm_ichat",
  },
];
