import { grantAccessToAcc } from "../../api/getAccounts";
import { blockUserMember, unblockUserMember } from "../../api/userApi";
import { removeUserAccount } from "../../api/usersApi";
import { NOTIFICATION_INFO } from "../../constants/constants";
import { showToast } from "../../modules/Notification/ActionNotification";

export const useTeamManagementHandlers = ({
  setData,
  setActiveModalInveractive,
  setModalData,
  setIsEditModal,
  setModals,
}) => {
  const handleModalSubmit = async (modalType, data) => {
    try {
      if (modalType === "removeAccount") {
        await handleRemoveUserAccount(data.accountId, data.userId);
        showToast("removeAccount", NOTIFICATION_INFO.ACCOUNT_REMOVED);
      } else if (modalType === "blockUser") {
        await blockUser(data.userId);
        showToast("blocked", NOTIFICATION_INFO.ACCOUNT_BLOCKED_ACTION);
      } else if (modalType === "unblockUser") {
        await unblockUser(data.userId);
        showToast("unblocked", NOTIFICATION_INFO.ACCOUNT_UNBLOCKED);
      } else if (modalType === "grantAccount") {
        console.log("data.accountID", data);
        const body = {
          userIds: data.accountID,
        };
        console.log("dataAll", data);
        await grantAccessToAcc(data.accountID, body);
        showToast("accGranted", NOTIFICATION_INFO.ACCOUNT_GRANTED);
      }
      setActiveModalInveractive(null);
      setModalData(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenModal = (modalType, data) => {
    setActiveModalInveractive(modalType);
    setModalData(data);
  };

  const unblockUser = async (idUser) => {
    try {
      await unblockUserMember(idUser);
      setData((prev) => {
        return prev.map((user) =>
          user.id === idUser ? { ...user, blocked: false } : user
        );
      });
    } catch (error) {
      console.error(error);
    }
  };

  const blockUser = async (idUser) => {
    try {
      await blockUserMember(idUser);
      setData((prev) => {
        return prev.map((user) =>
          user.id === idUser ? { ...user, blocked: true } : user
        );
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleRemoveUserAccount = async (accountId, userId) => {
    try {
      await removeUserAccount(accountId, userId);
      // setData((prevData) =>
      //   prevData.map((user) =>
      //     user.id === userId
      //       ? {
      //           ...user,
      //           accounts: user.accounts.filter(
      //             (account) => account.ID !== accountId
      //           ),
      //           accountsCount: user.accountsCount - 1,
      //         }
      //       : user
      //   )
      // );
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddUser = () => {
    setIsEditModal(false);
    setModals((prev) => ({
      ...prev,
      modalAddMember: true,
    }));
  };
  const handleEditUser = (user) => {
    console.log("user", user);
    setIsEditModal(true);
    setModalData(user);
    setModals((prev) => ({
      ...prev,
      modalAddMember: true,
    }));
  };

  return {
    handleEditUser,
    handleAddUser,
    handleRemoveUserAccount,
    handleModalSubmit,
    handleOpenModal,
  };
};
