import { axiosPublic } from "./axios";
import { API_PATHS } from "./constants";

export const twoFaCheckCode = async (data) => {
  const result = await axiosPublic.post(API_PATHS.CHECK_2FA_CODE, data);
  return result.data;
};

export const resendNew2faCode = async (data) => {
  return await axiosPublic.post(API_PATHS.SEND_2FA_CODE_AGAIN, data);
};
