import { axiosAuth, axiosPublic } from "./axios";
import { API_PATHS } from "./constants";

export const getUserData = async () => {
  const response = await axiosAuth.get(API_PATHS.USER_DATA);
  return response.data;
};

export const logoutUser = async () => {
  return axiosAuth.post(API_PATHS.LOGOUT_USER);
};

export const changeUserData = async (data) => {
  return axiosAuth.put(`${API_PATHS.USER_DATA}`, data);
};

export const userCreate = async (data) => {
  return axiosAuth.post(API_PATHS.CREATE_USER, data);
};

// query
export const getUserDataa = async () => {
  return await axiosAuth.get(API_PATHS.USER_DATA);
};

export const updateUser = async (id, body) => {
  return axiosAuth.put(`/api/users/${id}`, body);
};

export const blockUserMember = async (id) => {
  return axiosAuth.get(`/api/users/${id}/block`);
};

export const unblockUserMember = async (id) => {
  return axiosAuth.get(`/api/users/${id}/unblock`);
};
