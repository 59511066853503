import { axiosAuth } from "./axios";
import { API_PATHS } from "./constants";

export const getWidgetsValues = async () => {
  const result = await axiosAuth.get(API_PATHS.DASHBOARD_WIDGETS_VALUES);

  return result.data;
};

//WITH QUERY TANSTACK
export const getWidgetValues = () =>
  axiosAuth.get(API_PATHS.DASHBOARD_WIDGETS_VALUES);
