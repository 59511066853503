import { useForm } from "react-hook-form";
import Modal from "../Modal";
import { useEffect, useState } from "react";
import { AccountSelect } from "../../../components/AccountSelect/AccountSelect";
import { updateUser, userCreate } from "../../../api/userApi";

import { Button, Select } from "@mantine/core";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { StyledTextField } from "../../../components/Inputs/Input";
import { useQueryClient } from "@tanstack/react-query";
import { showToast } from "../../Notification/ActionNotification";
import { NOTIFICATION_INFO } from "../../../constants/constants";

const ModalAddMember = ({ setModals, modals, isEdit, userData }) => {
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      nickName: "",
      role: "",
      login2Fa: "One time password received via E-mail",
      loginApprove2Fa: "One time password received via E-mail",
    },
  });
  const { email, name, nickName, role } = watch();

  console.log("userData", userData);
  useEffect(() => {
    if (isEdit && userData) {
      setValue("name", userData.name || "");
      setValue("email", userData.email || "");
      setValue("nickName", userData.telegram || "");
      setValue("role", userData.role);
    }
  }, [isEdit, userData, setValue]);

  const addNewMember = async (data) => {
    try {
      const accounts = [...selectedAccounts.map((el) => `${el.ID}`)];
      const body = {
        name: data.name,
        email: data.email,
        telegram: data.nickName,
        "2fa_login_type": "email",
        "2fa_approve_type": "email",
        accounts: accounts,
      };

      if (isEdit) {
        delete body.email;
        console.log("body", body);

        await updateUser(userData.id, body);
        showToast("accUpdate", NOTIFICATION_INFO.ACCOUNT_UPDATED);
      } else {
        await userCreate(body);
        showToast("accCreate", NOTIFICATION_INFO.ACCOUNT_CREATED);
      }
      queryClient.invalidateQueries(["tableData"]);

      setModals((prev) => ({
        ...prev,
        modalAddMember: false,
      }));
      reset();
      setSelectedAccounts([]);
    } catch (error) {
      if (error.response.data.data.message) {
        setError("serverError", { message: error.response.data.data.message });
      }
      console.error(error.response.data.data);
    }
  };

  const handleCloseModal = () => {
    setModals((prev) => ({
      ...prev,
      modalAddMember: false,
    }));
    reset();
    setSelectedAccounts([]);
  };

  useEffect(() => {
    if (errors.serverError) {
      clearErrors("serverError");
    }
  }, [email, name, nickName]);

  return (
    <>
      {modals.modalAddMember && (
        <Modal>
          <div className={styles.containerModal}>
            <p className="H1">
              {isEdit ? `Redact ${name}` : `Add member ${name}`}
            </p>
            <span className={clsx("body1", styles.textModalDescription)}>
              {isEdit
                ? 'Make changes to users details and click on "Save" to save them.'
                : " Fill in the details of the new team member and click on 'Invite member' button to send him an invitation."}
            </span>

            <form onSubmit={handleSubmit(addNewMember)}>
              <div className={styles.containerInputs}>
                {errors.serverError && (
                  <p style={{ color: "red" }}>{errors.serverError.message}</p>
                )}
                <StyledTextField
                  id="outlined-basic"
                  label="Member's name"
                  variant="outlined"
                  {...register("name", { required: "Name is required" })}
                  error={!!errors.name}
                  placeholder="Enter a name"
                  helperText={errors.name?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ marginTop: "20px" }}
                />

                <StyledTextField
                  id="outlined-basic"
                  label="Authorization E-mail"
                  variant="outlined"
                  {...register("email", { required: "Email is required" })}
                  error={!!errors.email}
                  placeholder="Enter an E-mail"
                  helperText={errors.email?.message}
                  style={{ color: isEdit ? "#aaaaaa" : "black" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: isEdit,
                  }}
                  defaultValue={isEdit ? userData.email : ""}
                />
                <StyledTextField
                  id="outlined-basic"
                  label="Telegram nickname"
                  variant="outlined"
                  {...register("nickName", {
                    required: "Nickname is required",
                  })}
                  error={!!errors.name}
                  placeholder="@ Enter a nickname"
                  onChange={(event) => setValue("nickName", event.target.value)}
                  value={watch("nickName")}
                  helperText={errors.name?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Select
                  key="2FA-select"
                  label="2FA for login"
                  placeholder="One time password received via E-mail"
                  data={[
                    {
                      value: "One time password received via E-mail",
                      label: "One time password received via E-mail",
                    },
                  ]}
                  value={watch("login2Fa")}
                  onChange={(value) => setValue("login2Fa", value)}
                  error={errors.login2Fa && errors.login2Fa.message}
                  required
                />
                <Select
                  key="2FA-confirm-select"
                  label="2FA for approve"
                  placeholder="One time password received via E-mail"
                  data={[
                    {
                      value: "One time password received via E-mail",
                      label: "One time password received via E-mail",
                    },
                  ]}
                  value={watch("login2Fa")}
                  onChange={(value) => setValue("loginApprove2Fa", value)}
                  error={
                    errors.loginApprove2Fa && errors.loginApprove2Fa.message
                  }
                  required
                />
                <Select
                  key="role-select"
                  label="Role"
                  placeholder={role || "Assign a role"}
                  value={role}
                  data={[
                    {
                      value: "Member",
                      label: "Member",
                    },
                    {
                      value: "Team Lead",
                      label: "Team Lead",
                    },
                  ]}
                  onChange={(value) => setValue("role", value)}
                  error={errors.role && errors.role.message}
                  required
                />
              </div>
              <AccountSelect
                selectedAccounts={selectedAccounts}
                setSelectedAccounts={setSelectedAccounts}
              />
              <div className={styles.containerButtons}>
                <div className={styles.containerInviteAndText}>
                  <Button type="submit" className={styles.submitBtn}>
                    {isEdit ? "Save" : " Invite member"}
                  </Button>
                  <span className="body1">
                    {isEdit ? (
                      <>
                        We draw your attention, that{" "}
                        <strong>you can`t edit email after invitation</strong>,
                        if you want to use a new email for a user, you need to
                        create a new user account.
                      </>
                    ) : (
                      <>
                        <strong>
                          You can not edit email for an already added user
                        </strong>
                        , if you want to use a new email for a user, you need to
                        create a new user account.
                      </>
                    )}
                  </span>
                </div>
                <div className={styles.btnCloseModal}>
                  <button onClick={handleCloseModal} type="button">
                    Close
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ModalAddMember;

// <TextInput
// key="email-input"
// label="Authorization E-mail*"
// placeholder="Enter an E-mail"
// {...register("email", { required: "Name is required" })}
// error={errors.email && errors.email.message}
// required
// /> <TextInput
//                 key="nickname-input"
//                 label="Telegram nickname"
//                 placeholder="@ Enter a nickname"
//                 {...register("nickName", { required: "Name is required" })}
//                 error={errors.nickName && errors.nickName.message}
//                 required
//               />
//               <Select
//                 key="2FA-select"
//                 label="2FA for login"
//                 placeholder="One time password received via E-mail"
//                 data={[
//                   {
//                     value: "One time password received via E-mail",
//                     label: "One time password received via E-mail",
//                   },
//                 ]}
//                 value={watch("login2Fa")}
//                 onChange={(value) => setValue("login2Fa", value)}
//                 error={errors.login2Fa && errors.login2Fa.message}
//                 required
//               />
//               <Select
//                 key="2FA-confirm-select"
//                 label="2FA for approve"
//                 placeholder="One time password received via E-mail"
//                 data={[
//                   {
//                     value: "One time password received via E-mail",
//                     label: "One time password received via E-mail",
//                   },
//                 ]}
//                 value={watch("login2Fa")}
//                 onChange={(value) => setValue("loginApprove2Fa", value)}
//                 error={errors.loginApprove2Fa && errors.loginApprove2Fa.message}
//                 required
//               />
//               <Select
//                 key="role-select"
//                 label="Role"
//                 placeholder={role || "Assign a role"}
//                 value={role}
//                 data={[
//                   {
//                     value: "member",
//                     label: "Member",
//                   },
//                   {
//                     value: "teamLead",
//                     label: "Team Lead",
//                   },
//                 ]}
//                 onChange={(value) => setValue("role", value)}
//                 error={errors.role && errors.role.message}
//                 required
//               />
//               <AccountSelect
//                 selectedAccounts={selectedAccounts}
//                 setSelectedAccounts={setSelectedAccounts}
//               />
//               <Button type="submit">
//                 {isEdit ? "Save" : " Invite member"}
//               </Button>
//               <span>
//                 {isEdit
//                   ? "We draw your attention, that you can`t edit email after invitation, if you want to use a new email for a user, you need to create a new user account."
//                   : "You can not edit email for an already added user, if you want to use a new email for a user, you need to create a new user account."}
//               </span>
//               <button
//                 style={{ background: "red" }}
//                 onClick={handleCloseModal}
//                 type="button"
//               >
//                 Close
//               </button>
