import { axiosAuth } from "./axios";
import { API_PATHS } from "./constants";

export const getAccounts = async () => {
  const result = await axiosAuth.get(API_PATHS.ACCOUNTS);

  return result.data;
};

export const fundAccount = async (body) => {
  const result = await axiosAuth.post(API_PATHS.FUND_ACCOUNT, body);

  return result.data;
};

export const addUserToAccount = (id) => {
  return axiosAuth.post(`/api/accounts/${id}/add-users`);
};

export const changeAccount = (id, data) => {
  return axiosAuth.post(`/api/accounts/${id}`, data);
};

export const grantAccessToAcc = async (id, body) => {
  return axiosAuth.post(`/api/accounts/${id}/add-users`, body);
};
