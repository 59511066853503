import axios from "axios";
import { PAGE_ROUTES } from "../pages/constants";
const BASE_URL = "https://fbm-pay.com";

export const axiosAuth = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const axiosPublic = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// axiosAuth.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem("token");
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// axiosAuth.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     // if (error.response && error.response.status === 401) {
//     //   window.location.href = PAGE_ROUTES.LOGIN;
//     //   return;
//     // }
//     // if (error.response && error.response.status === 401) {
//     //   localStorage.removeItem("token");
//     //   window.location.href = PAGE_ROUTES.LOGIN;
//     // }
//     return Promise.reject(error);
//   }
// );

axiosPublic.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => Promise.reject(error)
);
