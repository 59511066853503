import LogoIcon from "../../icons/LogoIcon";
import Footer from "../footer/Footer";
import MenuBar from "../menu/MenuBar";
import styles from "./Sidebar.module.scss";

const Sidebar = () => {
  return (
    <aside className={styles.sideBar}>
      <div className={styles.sideBarContainer}>
        <div>
          <div className={styles.logoContainer}>
            <LogoIcon />
          </div>
          <div className={styles.sideBarNavigation}>
            <MenuBar />
          </div>
        </div>

        <Footer />
      </div>
    </aside>
  );
};

export default Sidebar;
